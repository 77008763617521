import { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../context/authContext";
import useOnClickOutside from "../hooks/useOnClickOutside";
import useToggle from "../hooks/useToggle";
import SvgLogout from "../icons/Logout.svg?react";
import SvgOpenInNew from "../icons/OpenInNew.svg?react";
import SvgPerson from "../icons/Person.svg?react";
import styles from "./AccountButton.module.css";
import Icon from "./ui/Icon";
import Profile from "./ui/Profile";

import { useCompany } from "../context/companyContext";
import SvgAdmin from "../icons/Admin.svg?react";
import SvgExitToApp from "../icons/ExitToApp.svg?react";
import { Permission } from "../types";

const AccountButton = () => {
  const { company } = useCompany();

  const canSettingsWrite =
    company?.permissions.includes(Permission.SettingsWrite) ?? false;

  const ref = useRef<HTMLDivElement>(null);
  const { user, logout } = useAuth();
  const menu = useToggle();

  useOnClickOutside(ref, menu.close);

  const isAdmin = useLocation().pathname.includes("administration");

  if (!user) {
    return null;
  }

  return (
    <div ref={ref} className={styles.wrapper}>
      <button className={styles.button} onClick={menu.toggle}>
        <Profile user={user} layout="compact" />
      </button>
      {menu.isOpen && (
        <div className={styles.menu}>
          <Profile user={user} className={styles.profile} layout="full" />
          <hr className={styles.separator} />
          <ul className={styles.list}>
            <li>
              <Link
                to="https://accounts.hgv.it"
                target="_blank"
                className={styles.menuItem}
                onClick={menu.close}
              >
                <Icon glyph={SvgPerson} className={styles.icon} />
                Konto
                <Icon glyph={SvgOpenInNew} className={styles.openInNew} />
              </Link>
            </li>
            {canSettingsWrite && (
              <li>
                {isAdmin ? (
                  <Link to="/" className={styles.menuItem} onClick={menu.close}>
                    <Icon glyph={SvgExitToApp} className={styles.icon} />
                    Zurück zur App
                  </Link>
                ) : (
                  <Link
                    to="/administration"
                    className={styles.menuItem}
                    onClick={menu.close}
                  >
                    <Icon glyph={SvgAdmin} className={styles.icon} />
                    Administration
                  </Link>
                )}
              </li>
            )}
            <li>
              <button className={styles.menuItem} onClick={logout}>
                <Icon glyph={SvgLogout} className={styles.icon} />
                Abmelden
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default AccountButton;
