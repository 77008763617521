import classNames from "classnames";
import styles from "./StatusLight.module.css";
import { EB } from "../../http/endbestaendeApi";
import i18n from "../../i18n";

interface Props {
  status: EB.Admin.WorksheetStatus;
}

const StatusLight = ({ status }: Props) => (
  <div className={styles.wrapper}>
    <div
      className={classNames(styles.statusLight, styles[status])}
      title={i18n.worksheet.status[status]}
    />
    {i18n.worksheet.status[status]}
  </div>
);

export default StatusLight;
