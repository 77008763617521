import { useState } from "react";

const useSelectRows = () => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const add = (row: number) => setSelectedRows([...selectedRows, row]);
  const remove = (row: number) =>
    setSelectedRows(selectedRows.filter((s) => s !== row));

  const addRows = (rows: number[]) => {
    const newSelectedRows = [...new Set([...selectedRows, ...rows])];
    setSelectedRows(newSelectedRows);
  };

  const toggle = (row: number) =>
    selectedRows.includes(row) ? remove(row) : add(row);

  return {
    rows: selectedRows,
    set: setSelectedRows,
    toggle,
    addRows,
  };
};

export default useSelectRows;
