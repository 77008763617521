import { useNavigate } from "react-router-dom";
import { EB } from "../http/endbestaendeApi";
import Listbox from "./ui/Listbox";
import { useEffect } from "react";

interface Props {
  worksheet?: EB.WorksheetData;
  worksheets: EB.WorksheetData[];
}

const WorksheetSelect = ({ worksheet, worksheets }: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (worksheet || worksheets.length < 1) return;
    navigate(`/worksheets/${worksheets[0]?.id}/categories`);
  }, [worksheets]);

  return (
    <Listbox
      label="Jahr:"
      value={worksheet}
      items={worksheets}
      onChange={(v) => navigate(`/worksheets/${v.id}/categories`)}
      displayValue={(v) => `${getFullYear(v.end_date)}`}
      compare={(a, b) => getFullYear(a.end_date) === getFullYear(b.end_date)}
    />
  );
};

const getFullYear = (date: string) => new Date(date).getFullYear();

export default WorksheetSelect;
