import { useWorksheet } from "../context/worksheetContext";
import { EB } from "../http/endbestaendeApi";
import Categories from "./Categories";
import styles from "./CategoryPage.module.css";
import ErrorPage from "./ErrorPage";
import ErrorText from "./ui/ErrorText";
import LoadingSpinner from "./ui/LoadingSpinner";

interface Props {
  companyId: EB.CompanyId;
}

const CategoryPage = ({ companyId }: Props) => {
  const { worksheet, isValidating, errorCode, mutate } = useWorksheet();
  const isSuccess = worksheet && !isValidating && !errorCode;

  return (
    <>
      {isSuccess && (
        <Categories
          worksheet={worksheet}
          mutate={mutate}
          companyId={companyId}
        />
      )}

      {isValidating && (
        <div className={styles.loadingSpinner}>
          <LoadingSpinner />
        </div>
      )}

      {errorCode && (
        <ErrorPage title="Fehler beim Laden der Daten.">
          {errorCode === 404 && (
            <ErrorText text="Dieses Jahr konnte nicht gefunden werden." />
          )}
        </ErrorPage>
      )}
    </>
  );
};

export default CategoryPage;
