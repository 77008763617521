import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import welcomingSVG from "../assets/welcoming.svg";
import { useAuth } from "../context/authContext";
import { useCompany } from "../context/companyContext";
import NotificationDispatch, {
  showErrorNotification,
} from "../context/notificationContext";
import { WorksheetProvider } from "../context/worksheetContext";
import { postWorksheetData } from "../http/endbestaendeApi";
import SvgForwardArrow from "../icons/ForwardArrow.svg?react";
import CategoryPage from "./CategoryPage";
import CompanySelect from "./CompanySelect";
import DetailPage from "./DetailPage";
import EmptyPage from "./EmptyPage";
import ErrorPage from "./ErrorPage";
import Header from "./Header";
import styles from "./Home.module.css";
import Worksheet from "./Worksheet";
import WorksheetSelect from "./WorksheetSelect";
import Button from "./ui/Button";

const Home = () => {
  const auth = useAuth();
  const { company, status } = useCompany();
  const dispatch = useContext(NotificationDispatch);

  if (!auth.session) return null;

  return (
    <>
      <Header>
        <CompanySelect />
      </Header>
      {status === "success" &&
        (!company ? (
          <EmptyPage title="Kein Betrieb gewählt" image={welcomingSVG}>
            Bitte wählen Sie einen Ihrer Betriebe aus.
          </EmptyPage>
        ) : (
          <Routes>
            <Route index element={<Navigate to="worksheets" replace />} />
            <Route path="worksheets">
              <Route
                index
                element={
                  <Worksheet companyId={company.id}>
                    {({ worksheets, mutate }) =>
                      worksheets.length > 0 ? (
                        <div className={styles.date}>
                          <WorksheetSelect worksheets={worksheets} />
                        </div>
                      ) : (
                        <EmptyPage
                          title="Willkommen bei HGV Endbestände"
                          image={welcomingSVG}
                          button={
                            <Button
                              buttonProps={{
                                onClick: () => {
                                  postWorksheetData(company.id, {
                                    member_id: company.member_id,
                                  })
                                    .then((res) =>
                                      mutate({
                                        worksheets: [...worksheets, res],
                                      }),
                                    )
                                    .catch((err) =>
                                      dispatch(
                                        showErrorNotification(
                                          err,
                                          "Es ist ein Fehler aufgetreten.",
                                        ),
                                      ),
                                    );
                                },
                              }}
                              glyph={SvgForwardArrow}
                            >
                              Jetzt loslegen
                            </Button>
                          }
                        >
                          Hier haben Sie die Möglichkeit, die jährliche
                          Erfassung der Endbestände an Waren und Gütern
                          <br /> in Ihrem Betrieb durchzuführen.
                        </EmptyPage>
                      )
                    }
                  </Worksheet>
                }
              />
              <Route path=":worksheetId">
                <Route index element={<Navigate to="categories" replace />} />
                <Route path="categories">
                  <Route
                    index
                    element={
                      <WorksheetProvider>
                        <CategoryPage companyId={company.id} />
                      </WorksheetProvider>
                    }
                  />
                  <Route
                    path=":categoryId"
                    element={
                      <WorksheetProvider>
                        <DetailPage />
                      </WorksheetProvider>
                    }
                  />
                </Route>
              </Route>
            </Route>
          </Routes>
        ))}

      {status === "failure" && (
        <ErrorPage title="Fehler beim Laden der Betriebsdaten"></ErrorPage>
      )}
    </>
  );
};

export default Home;
