import { useParams } from "react-router";

export const useCategoryId = () => {
  const params = useParams<"categoryId">();
  return Number(params.categoryId);
};

export const useWorksheetId = () => {
  const params = useParams<"worksheetId">();
  return Number(params.worksheetId);
};
