import useToggle from "../../hooks/useToggle";
import { EB } from "../../http/endbestaendeApi";
import SvgEdit from "../../icons/Edit.svg?react";
import Button from "../ui/Button";
import FormField from "../ui/FormField";
import Input from "../ui/Input";
import Modal from "../ui/Modal";

interface Props {
  title: string;
  endDate: string;
  onSubmit: (body: EB.Admin.PatchMemberDataBody) => void;
}

const EditEndDateModal = ({ title, endDate, onSubmit }: Props) => {
  const toggle = useToggle();

  const date = new Date(endDate);

  return (
    <>
      <Button
        glyph={SvgEdit}
        type="control"
        buttonProps={{
          onClick: (e) => (toggle.open(), e.stopPropagation()),
          title: "Enddatum bearbeiten",
        }}
      >
        Bearbeiten
      </Button>
      {toggle.isOpen && (
        <Modal<EB.Admin.PatchMemberDataBody>
          title={title}
          isOpen={toggle.isOpen}
          onClose={toggle.close}
          onSubmit={onSubmit}
          defaultValues={{ end_date: date.toLocaleDateString("en-CA") }}
        >
          {({ register, formState: { errors } }) => (
            <FormField label="Enddatum" error={errors.end_date}>
              {({ labelId, isOptional, isInvalid }) => (
                <Input
                  id={labelId}
                  isInvalid={isInvalid}
                  type="date"
                  min={`${date.getFullYear()}-01-01`}
                  max={`${date.getFullYear()}-12-31`}
                  {...register("end_date", {
                    required: !isOptional,
                    valueAsDate: true,
                  })}
                />
              )}
            </FormField>
          )}
        </Modal>
      )}
    </>
  );
};

export default EditEndDateModal;
