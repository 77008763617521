import Page from "./ui/Page";
import { EB, useCategoryData } from "../http/endbestaendeApi";
import Products from "./Products";
import styles from "./Detail.module.css";
import LoadingSpinner from "./ui/LoadingSpinner";
import { getRemoteDataStatus } from "../utils";
import { useCategoryId } from "../hooks/useRouterParams";
import ErrorPage from "./ErrorPage";

interface Props {
  worksheet: EB.WorksheetData;
}

const Detail = ({ worksheet }: Props) => {
  const categoryId = useCategoryId();
  const {
    data: category,
    isValidating,
    error,
  } = useCategoryData(worksheet.id, categoryId);
  const status = getRemoteDataStatus({ isValidating, error });

  return (
    <>
      {status === "success" && (
        <Page
          title={category?.name ?? ""}
          backLinkText="Zurück zu den Kategorien"
        >
          <Products worksheet={worksheet} categoryId={categoryId} />
        </Page>
      )}

      {status === "validating" && (
        <div className={styles.loadingSpinner}>
          <LoadingSpinner />
        </div>
      )}

      {status === "failure" && (
        <ErrorPage title="Fehler beim Laden der Daten." />
      )}
    </>
  );
};

export default Detail;
