import { PropsWithChildren, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

type Props = PropsWithChildren;

const modalRoot = document.querySelector("#modal-root");

const ModalRoot = ({ children }: Props) => {
  const elRef = useRef(document.createElement("div"));

  useEffect(() => {
    if (!modalRoot) throw new Error("#modal-root not found");
    modalRoot.appendChild(elRef.current);

    return () => {
      modalRoot.removeChild(elRef.current);
    };
  }, []);

  return createPortal(children, elRef.current);
};

export default ModalRoot;
