import { useEffect, useReducer } from "react";
import { useSearchParams } from "react-router-dom";
import { ListFilters } from "../types";

type Action =
  | { type: "NEXT_PAGE" }
  | { type: "PREV_PAGE" }
  | { type: "SET_PAGE"; page: number }
  | { type: "SET_SEARCH_TERM"; term: string };

const reducer = (state: ListFilters, action: Action): ListFilters => {
  switch (action.type) {
    case "NEXT_PAGE":
      return { ...state, page: state.page + 1 };
    case "PREV_PAGE":
      if (state.page === 1) return state;
      return { ...state, page: state.page - 1 };
    case "SET_PAGE":
      return { ...state, page: action.page };
    case "SET_SEARCH_TERM":
      return { ...state, q: action.term, page: 1 };
  }
};

export interface UseListFiltersReturn {
  state: ListFilters;
  setPage: (page: number) => void;
  nextPage: () => void;
  prevPage: () => void;
  setSearchTerm: (term: string) => void;
  isSearchable: boolean;
}

interface UseListFiltersOptions {
  isSearchable: boolean;
  initialState: ListFilters;
}

const useListFilters = (
  userOptions?: Partial<UseListFiltersOptions>,
): UseListFiltersReturn => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialState: ListFilters = {
    page: Number(searchParams.get("seite") ?? 1),
    limit: 50,
  };

  const options: UseListFiltersOptions = {
    isSearchable: false,
    initialState,
    ...userOptions,
  };
  const [state, dispatch] = useReducer(reducer, options.initialState);

  const setPage = (page: number) => dispatch({ type: "SET_PAGE", page });

  useEffect(() => {
    const pageParam = searchParams.get("seite");
    if (pageParam === String(state.page)) return;
    searchParams.set("seite", String(state.page));
    setSearchParams(searchParams, { replace: !pageParam });
  }, [state.page]);

  useEffect(() => {
    const page = Number(searchParams.get("seite"));
    if (page !== state.page) setPage(page > 0 ? page : 1);
  }, [searchParams]);

  return {
    state,
    setPage,
    nextPage: () => dispatch({ type: "NEXT_PAGE" }),
    prevPage: () => dispatch({ type: "PREV_PAGE" }),
    setSearchTerm: (term: string) =>
      dispatch({ type: "SET_SEARCH_TERM", term }),
    isSearchable: options.isSearchable,
  };
};

export default useListFilters;
