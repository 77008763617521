import { useRef } from "react";
import SvgClose from "../../icons/Close.svg?react";
import SvgSearch from "../../icons/Search.svg?react";
import Icon from "./Icon";
import Input from "./Input";
import LoadingSpinner from "./LoadingSpinner";
import styles from "./Search.module.css";

interface Props {
  placeholder: string;
  isValidating: boolean;
  value: string;
  onChange: (value: string) => void;
}

const Search = ({ value, placeholder, isValidating, onChange }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={styles.searchField} role="search">
      <Input
        className={styles.searchInput}
        autoComplete="off"
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
      <div className={styles.leading} onClick={() => inputRef.current?.focus()}>
        <>
          {value === "" && (
            <LoadingSpinner size="small" isLoading={isValidating} delayed={100}>
              <Icon glyph={SvgSearch} className={styles.searchIcon} />
            </LoadingSpinner>
          )}
          {value !== "" && (
            <Icon
              glyph={SvgClose}
              className={styles.clearIcon}
              onClick={() => onChange("")}
            />
          )}
        </>
      </div>
    </div>
  );
};

export default Search;
