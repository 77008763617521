import classNames from "classnames";
import { User, isEmployee } from "../../context/authContext";
import styles from "./Profile.module.css";

interface Props {
  user: User;
  className?: string;
  layout: "full" | "compact";
  notificationCount?: number;
}

const getInitialChar = (s: string): string => {
  return s.charAt(0).toUpperCase();
};

const getUserInitials = ({ name, email }: User) => {
  const split = (name ?? email).split(" ");
  const numNames = split.length;

  switch (numNames) {
    case 1:
      return getInitialChar(split[0]!);
    default:
      const first = split[0]!;
      const last = split[numNames - 1]!;
      return getInitialChar(first) + getInitialChar(last);
  }
};

const Profile = ({ user, className, layout }: Props) => (
  <div className={classNames(styles.profile, className)}>
    <div className={styles.avatar}>
      {isEmployee(user.email) && user.profilePhoto ? (
        <img src={user.profilePhoto} className={styles.image} />
      ) : (
        <div className={`${styles.image} ${styles.defaultImage}`}>
          {getUserInitials(user)}
        </div>
      )}
    </div>
    {layout === "full" && (
      <div className={styles.identity}>
        <div className={styles.name}>{user.name}</div>
        <div className={styles.email}>{user.email}</div>
      </div>
    )}
  </div>
);

export default Profile;
