import { ReactNode } from "react";
import { UseListFiltersReturn } from "../../hooks/useListFilters";
import SvgNavigateNext from "../../icons/NagivateNext.svg?react";
import SvgNavigateBefore from "../../icons/NavigateBefore.svg?react";
import { Glyph, RemoteDataStatus } from "../../types";
import ErrorText from "./ErrorText";
import Icon from "./Icon";
import LoadingSpinner from "./LoadingSpinner";
import styles from "./Table.module.css";

interface Props {
  filters?: UseListFiltersReturn;
  controls?: ReactNode;
  total?: number;
  head?: ReactNode;
  body: ReactNode;
  showPaginateBtn?: boolean;
  actions?: ReactNode;
  remoteDataStatus?: RemoteDataStatus;
}

const Table = ({
  filters,
  controls,
  total,
  head,
  body,
  showPaginateBtn = false,
  actions,
  remoteDataStatus = "success",
}: Props) => {
  const showPagination = filters && (!!total || showPaginateBtn);
  const page = filters?.state.page ?? 1;
  const limit = filters?.state.limit ?? 5;

  const fromItem = (page - 1) * limit + 1;
  const maxToItem = fromItem + limit - 1;
  const toItem = total ? Math.min(maxToItem, total) : maxToItem;

  return (
    <>
      {controls && <div className={styles.controls}>{controls}</div>}
      <div className={styles.wrapper}>
        {actions && <div>{actions}</div>}
        <table className={styles.table}>
          {head && <thead>{head}</thead>}
          {remoteDataStatus === "success" && total !== 0 && (
            <tbody>{body}</tbody>
          )}
        </table>

        {remoteDataStatus === "success" && total === 0 && (
          <div className={styles.status}>Keine Einträge gefunden.</div>
        )}

        {remoteDataStatus === "validating" && (
          <div className={styles.loadingSpinner}>
            <LoadingSpinner delayed={0} />
          </div>
        )}

        {remoteDataStatus === "failure" && (
          <div className={styles.status}>
            <ErrorText text="Fehler beim Laden der Einträge." />
          </div>
        )}
      </div>

      {remoteDataStatus === "success" && (actions || showPagination) && (
        <div className={styles.tableFooter}>
          {showPagination && (
            <div className={styles.pagination}>
              <div>
                {fromItem} – {toItem} {total && `von ${total}`}
              </div>
              <PaginateBtn
                glyph={SvgNavigateBefore}
                title={`Vorherige ${filters.state.limit} Zeilen`}
                disabled={filters.state.page === 1}
                onClick={filters.prevPage}
              />
              <PaginateBtn
                glyph={SvgNavigateNext}
                title={`Nächste ${filters.state.limit} Zeilen`}
                disabled={total ? toItem === total : false}
                onClick={filters.nextPage}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

interface PaginateBtnProps {
  glyph: Glyph;
  title: string;
  onClick: () => void;
  disabled?: boolean;
}

const PaginateBtn = ({ glyph, title, onClick, disabled }: PaginateBtnProps) => (
  <button
    className={styles.paginationButton}
    title={title}
    onClick={onClick}
    disabled={disabled}
  >
    <Icon className={styles.paginationButtonIcon} glyph={glyph} />
  </button>
);

export default Table;
