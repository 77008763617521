import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider, RequireAuth } from "../context/authContext";
import { CompanyProvider, useCompany } from "../context/companyContext";
import NotificationDispatch, {
  useNotification,
} from "../context/notificationContext";
import { Permission } from "../types";
import styles from "./App.module.css";
import Home from "./Home";
import AdminApp from "./admin/AdminApp";
import Notification from "./ui/Notification";

const App = () => {
  const [{ show, severity, message }, dispatch] = useNotification();

  return (
    <BrowserRouter>
      <AuthProvider>
        <NotificationDispatch.Provider value={dispatch}>
          <div className={styles.app}>
            <RequireAuth>
              <CompanyProvider>
                <Main />
              </CompanyProvider>
            </RequireAuth>
          </div>
          {show && <Notification type={severity} text={message ?? ""} />}
        </NotificationDispatch.Provider>
      </AuthProvider>
    </BrowserRouter>
  );
};

const Main = () => {
  const { company } = useCompany();

  const canWriteSettings =
    company?.permissions.includes(Permission.SettingsWrite) ?? false;

  return (
    <Routes>
      <Route path="/*" element={<Home />} />
      {canWriteSettings && (
        <Route path="administration/*" element={<AdminApp />} />
      )}
    </Routes>
  );
};

export default App;
