import classNames from "classnames";
import { useCombobox } from "downshift";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCompany } from "../context/companyContext";
import useOnClickOutside from "../hooks/useOnClickOutside";
import useToggle from "../hooks/useToggle";
import { EB, useCompanies } from "../http/endbestaendeApi";
import SvgClose from "../icons/Close.svg?react";
import SvgExpandLess from "../icons/ExpandLess.svg?react";
import SvgExpandMore from "../icons/ExpandMore.svg?react";
import SvgSearch from "../icons/Search.svg?react";
import styles from "./CompanySelect.module.css";
import ErrorText from "./ui/ErrorText";
import HighlightText from "./ui/HighlightText";
import Icon from "./ui/Icon";
import Input from "./ui/Input";
import LoadingSpinner from "./ui/LoadingSpinner";

interface Props {
  className?: string;
}

const CompanySelect = ({ className }: Props) => {
  const { company, hasOnlyOneCompany, setCompanyId, status } = useCompany();
  const navigate = useNavigate();

  const menu = useToggle();
  const searchRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useOnClickOutside(searchRef, menu.close);

  useEffect(() => {
    if (!menu.isOpen) return;

    inputRef.current?.focus();

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape" || e.key === "Tab") menu.close();
    };
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [menu.isOpen]);

  const companyInfo = (
    <div className={styles.companyInfo}>
      {company ? (
        <>
          <span className={styles.companyName}>{company.name}</span>
          <span className={styles.memberName}>{company.member_name}</span>
        </>
      ) : (
        "Wählen Sie einen Betrieb aus …"
      )}
    </div>
  );

  return (
    <div
      className={classNames(styles.companySelect, className, {
        [styles.companySelectOpen]: menu.isOpen,
      })}
      ref={searchRef}
    >
      {status === "validating" ? (
        <LoadingSpinner
          className={styles.loadingSpinner}
          delayed={175}
          size="small"
        />
      ) : hasOnlyOneCompany ? (
        companyInfo
      ) : (
        <button
          className={classNames(styles.button, {
            [styles.buttonOpen]: menu.isOpen,
          })}
          onClick={menu.toggle}
        >
          {companyInfo}
          <Icon
            glyph={menu.isOpen ? SvgExpandLess : SvgExpandMore}
            className={styles.expandIcon}
          />
        </button>
      )}

      {menu.isOpen && (
        <CompanySelectMenu
          ref={inputRef}
          onSelectedItemChange={(c) => {
            menu.close();
            setCompanyId(c.id);
            navigate(`/worksheets`);
          }}
        />
      )}
    </div>
  );
};

interface CompanySelectMenuProps {
  onSelectedItemChange: (item: EB.Company) => void;
}

const CompanySelectMenu = forwardRef<HTMLInputElement, CompanySelectMenuProps>(
  ({ onSelectedItemChange }: CompanySelectMenuProps, ref) => {
    const [inputValue, setInputValue] = useState("");

    const { data, isValidating, error } = useCompanies({
      q: inputValue,
      page: 1,
      limit: 10,
    });
    const isSuccess = !isValidating && !error;

    const items = data?.items ?? [];

    const { getMenuProps, getInputProps, highlightedIndex, getItemProps } =
      useCombobox({
        defaultIsOpen: true,
        items,
        itemToString: (item) => (item ? item.name : ""),
        inputValue,
        onStateChange: ({ inputValue, type, selectedItem }) => {
          switch (type) {
            case useCombobox.stateChangeTypes.InputChange:
              setInputValue(inputValue ?? "");
              break;
            case useCombobox.stateChangeTypes.InputKeyDownEnter:
            case useCombobox.stateChangeTypes.ItemClick:
            case useCombobox.stateChangeTypes.InputBlur:
              if (!selectedItem) return;
              setInputValue(selectedItem.name);
              onSelectedItemChange(selectedItem);
              break;
            default:
              break;
          }
        },
      });

    return (
      <div className={styles.menu}>
        <hr className={styles.separator} />
        <div className={styles.search}>
          <div className={styles.combobox}>
            <Input
              {...getInputProps({ ref })}
              type="search"
              placeholder="Suche Betriebe …"
            />
          </div>
          {inputValue === "" && (
            <Icon glyph={SvgSearch} className={styles.searchIcon} />
          )}
          {inputValue !== "" && (
            <Icon
              glyph={SvgClose}
              className={styles.clearIcon}
              onClick={() => setInputValue("")}
            />
          )}
        </div>
        <span className={styles.title}>Betriebe</span>
        <dl {...getMenuProps()} className={styles.suggestions}>
          {isSuccess && items.length === 0 && (
            <p className={styles.emptyText}>
              {inputValue !== "" ? (
                <span>
                  Es wurden keine mit Ihrer Suchanfrage -{" "}
                  <strong>{inputValue}</strong> - übereinstimmende Betriebe
                  gefunden.
                </span>
              ) : (
                <span>Keine Betriebe gefunden.</span>
              )}
            </p>
          )}

          {isValidating && (
            <div className={styles.status}>
              <LoadingSpinner delayed={175} />
            </div>
          )}

          {error && (
            <div className={styles.status}>
              <ErrorText text="Fehler beim Laden der Betriebe." />
            </div>
          )}

          {data?.items.map((item, index) => (
            <dd
              className={classNames(styles.suggestion, {
                [styles.highlightedSuggestion]: index === highlightedIndex,
              })}
              key={index}
              {...getItemProps?.({
                item,
                index,
              })}
            >
              <div className={styles.companyInfo}>
                <HighlightText
                  className={styles.companyName}
                  text={item.name}
                  highlight={inputValue}
                />
                <HighlightText
                  className={styles.memberName}
                  text={item.member_name}
                  highlight={inputValue}
                />
              </div>
            </dd>
          ))}
        </dl>
      </div>
    );
  },
);

CompanySelectMenu.displayName = "CompanySelectMenu";

export default CompanySelect;
