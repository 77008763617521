import { FunctionComponent, SVGProps } from "react";

export type Glyph = FunctionComponent<SVGProps<SVGSVGElement>>;

export type RemoteDataStatus = "success" | "validating" | "failure";

export interface ListFilters {
  page: number;
  limit: number;
  q?: string;
}

export type PaginationQuery<T = object> = T & Partial<ListFilters>;

export interface FetcherOptions {
  url: string;
  query?: object;
  signal?: AbortSignal;
}

export interface Searchable {
  query: {
    q: string;
  };
  signal: AbortSignal;
}

export enum Permission {
  WorksheetsWrite = "endbestaende.worksheets:write",
  SettingsWrite = "endbestaende.settings:write",
}
