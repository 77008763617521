import { useContext, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import NotificationDispatch, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import {
  postMemberSetting,
  useMemberSettingsWorksheetsYears,
} from "../../http/endbestaendeApi";
import Header from "../Header";
import Page from "../ui/Page";
import AddMemberModal from "./AddMemberModal";
import styles from "./AdminApp.module.css";
import Worksheets from "./Worksheets";

const AdminApp = () => {
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear - 1);
  const { data } = useMemberSettingsWorksheetsYears();
  const dispatch = useContext(NotificationDispatch);

  return (
    <>
      <Header />
      <Page
        title="Administration"
        actions={
          <div className={styles.buttons}>
            <AddMemberModal
              onSubmit={(value) =>
                postMemberSetting(value)
                  .then(() =>
                    dispatch(
                      showSuccessNotification(
                        "Mitglied erfolgreich hinzugefügt.",
                      ),
                    ),
                  )
                  .catch(() =>
                    dispatch(
                      showErrorNotification("Es ist ein Fehler aufgetreten."),
                    ),
                  )
              }
            />
          </div>
        }
      >
        <Routes>
          <Route
            path="worksheets"
            element={<Worksheets year={year} setYear={setYear} years={data} />}
          />
          <Route path="*" element={<Navigate to="worksheets" replace />} />
        </Routes>
      </Page>
    </>
  );
};

export default AdminApp;
