import classNames from "classnames";
import { ReactNode, useId } from "react";
import { FieldError } from "react-hook-form";
import styles from "./FormField.module.css";

interface Props {
  label: string;
  children:
    | ReactNode
    | ((props: {
        labelId: string;
        isOptional: boolean;
        disabled: boolean;
        isInvalid: boolean;
      }) => ReactNode);
  description?: ReactNode;
  isOptional?: boolean;
  disabled?: boolean;
  error?: FieldError;
  direction?: "column" | "row";
}

const FormField = ({
  label,
  description,
  children,
  isOptional = false,
  disabled = false,
  error,
  direction = "row",
}: Props) => {
  const labelId = useId();
  const isInvalid = error !== undefined;

  return (
    <div
      className={classNames(styles.field, {
        [styles.disabled]: disabled,
        [styles.column]: direction === "column",
        [styles.row]: direction === "row",
      })}
    >
      <div className={styles.leftColumn}>
        <label htmlFor={labelId} className={styles.label}>
          {label}
          {isOptional && <span>(optional)</span>}
        </label>
        {description && <div className={styles.description}>{description}</div>}
      </div>
      <div
        className={classNames(styles.rightColumn, {
          [styles.row]: direction === "row",
        })}
      >
        {typeof children === "function"
          ? children({
              labelId,
              disabled,
              isOptional,
              isInvalid,
            })
          : children}
        {isInvalid && <Error error={error} />}
      </div>
    </div>
  );
};

const Error = ({ error }: { error: FieldError }) => (
  <p className={styles.error}>{getErrorMessage(error)}</p>
);

const getErrorMessage = ({ message, type }: FieldError): string => {
  if (message) return message;

  switch (type) {
    case "required":
      return "Das Feld muss einen Wert beinhalten.";
    case "min":
      return "Das Feld enthält keinen gültigen Wert, geben Sie einen größeren Wert ein.";
    case "max":
      return "Das Feld enthält keinen gültigen Wert, geben Sie einen kleineren Wert ein.";
    default:
      return "";
  }
};

FormField.Error = Error;

export default FormField;
