const i18n = {
  title: "HGV Endbestände",
  units: { piece: "Stück", kilogram: "Kilogramm", liter: "Liter" },
  worksheet: {
    status: {
      not_started: "Nicht begonnen",
      in_progress: "In Bearbeitung",
      completed: "Abgeschlossen",
    },
  },
};

export default i18n;
