import { MouseEventHandler } from "react";
import { Glyph } from "../../types";
import Icon from "./Icon";
import styles from "./TableButton.module.css";

interface Props {
  glyph: Glyph;
  title?: string;
  onClick: MouseEventHandler;
  disabled?: boolean;
}

const TableButton = ({ glyph, title, onClick, disabled = false }: Props) => (
  <button
    className={styles.tableButton}
    title={title}
    onClick={onClick}
    disabled={disabled}
  >
    <Icon className={styles.icon} glyph={glyph} />
  </button>
);

export default TableButton;
