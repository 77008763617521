import { KeyedMutator } from "swr";
import { EB } from "../http/endbestaendeApi";
import SvgLock from "../icons/Lock.svg?react";
import styles from "./Categories.module.css";
import CategoryList from "./CategoryList";
import Worksheet from "./Worksheet";
import WorksheetSelect from "./WorksheetSelect";
import Icon from "./ui/Icon";

interface Props {
  companyId: EB.CompanyId;
  worksheet: EB.WorksheetData;
  mutate: KeyedMutator<EB.WorksheetData>;
}

const Categories = ({ companyId, worksheet, mutate }: Props) => (
  <Worksheet pageTitle="Lagerbestand" companyId={companyId}>
    {({ worksheets, mutate: mutateWorksheets }) => (
      <>
        <div className={styles.date}>
          <WorksheetSelect worksheet={worksheet} worksheets={worksheets} />
          {!!worksheet.locked_at && (
            <div
              className={styles.lock}
              title="Vom HGV als abgeschlossen angesehen und gesperrt"
            >
              <Icon glyph={SvgLock} className={styles.lockIcon} />
              <span className={styles.lockLabel}>Gesperrt</span>
            </div>
          )}
        </div>
        <CategoryList
          worksheet={worksheet}
          worksheets={worksheets}
          mutateWorksheet={(updated) => {
            mutate(updated, { revalidate: false });
            mutateWorksheets(
              {
                worksheets: worksheets.map((w) =>
                  w.id === updated.id ? updated : w,
                ),
              },
              { revalidate: false },
            );
          }}
        />
      </>
    )}
  </Worksheet>
);

export default Categories;
