import { Controller } from "react-hook-form";
import useToggle from "../../hooks/useToggle";
import { EB, useSearchMemberSettings } from "../../http/endbestaendeApi";
import SvgAdd from "../../icons/Add.svg?react";
import Button from "../ui/Button";
import Combobox from "../ui/Combobox";
import FormField from "../ui/FormField";
import Input from "../ui/Input";
import Modal from "../ui/Modal";

interface Props {
  onSubmit: (body: EB.Admin.PostMemberSettingBody) => void;
}

const AddMemberModal = ({ onSubmit }: Props) => {
  const toggle = useToggle();
  const date = new Date();

  return (
    <>
      <Button
        glyph={SvgAdd}
        buttonProps={{
          onClick: (e) => (toggle.open(), e.stopPropagation()),
          title: "Mitglied hinzufügen",
        }}
      >
        Mitglied hinzufügen
      </Button>
      {toggle.isOpen && (
        <Modal<{ member: EB.Admin.MemberSetting; end_date: string }>
          title=" Mitglied hinzufügen"
          isOpen={toggle.isOpen}
          onClose={toggle.close}
          onSubmit={(values) => (
            onSubmit({
              member_id: values.member.id,
              end_date: values.end_date,
            }),
            toggle.close()
          )}
          defaultValues={{ end_date: `${date.getFullYear()}-12-31` }}
        >
          {({ control, register, formState: { errors } }) => (
            <>
              <FormField
                label="Mitglied"
                error={errors.member?.id}
                direction="column"
              >
                {({ labelId }) => (
                  <Controller
                    name="member"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Combobox.Async
                        labelId={labelId}
                        value={field.value}
                        onChange={field.onChange}
                        fetcher={useSearchMemberSettings}
                        displayValue={(member) => member.name}
                        placeholder="Mitglied suchen..."
                      />
                    )}
                  />
                )}
              </FormField>
              <FormField
                label="Enddatum"
                error={errors.end_date}
                direction="column"
              >
                {({ labelId, isOptional, isInvalid }) => (
                  <Input
                    id={labelId}
                    isInvalid={isInvalid}
                    type="date"
                    min={`${date.getFullYear()}-01-01`}
                    max={`${date.getFullYear()}-12-31`}
                    {...register("end_date", {
                      required: !isOptional,
                      valueAsDate: true,
                    })}
                  />
                )}
              </FormField>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default AddMemberModal;
