import { Combobox as HeadlessCombobox, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";
import { SWRResponse } from "swr";
import useSearchFilter from "../../hooks/useSearchFilter";
import SvgExpandMore from "../../icons/ExpandMore.svg?react";
import { Searchable } from "../../types";
import styles from "./Combobox.module.css";
import Icon from "./Icon";
import inputStyles from "./Input.module.css";
import LoadingSpinner from "./LoadingSpinner";

interface BaseProps<T> {
  labelId: string;
  placeholder?: string;
  isDisabled?: boolean;
  isValidating?: boolean;
  displayValue: (value: T) => string;
  onChange: (value: T) => void;
  value?: T;
}

interface ComboboxProps<T> extends BaseProps<T> {
  items: T[];
  onInputChange: (query: string) => void;
}

const Combobox = <T extends object>({
  labelId,
  value,
  items,
  onChange,
  onInputChange,
  isDisabled,
  isValidating,
  placeholder = "Auswählen...",
  displayValue,
}: ComboboxProps<T>) => {
  const [query, setQuery] = useState("");

  useEffect(() => onInputChange(query), [query]);

  return (
    <HeadlessCombobox
      disabled={isDisabled}
      value={value}
      onChange={onChange}
      nullable
    >
      <div className={styles.combobox}>
        <div className={styles.input}>
          {/* TODO: remove button wrapper after this https://github.com/tailwindlabs/headlessui/discussions/1236 */}
          <HeadlessCombobox.Button as="div">
            <HeadlessCombobox.Input
              id={labelId}
              className={classNames(inputStyles.input, {
                [styles.disabled]: isDisabled,
              })}
              autoComplete="off"
              placeholder={placeholder}
              onChange={(e) => setQuery(e.target.value)}
              displayValue={(item: T | undefined) =>
                item ? displayValue(item) : ""
              }
            />
          </HeadlessCombobox.Button>
          <HeadlessCombobox.Button
            className={classNames(styles.button, {
              [styles.disabled]: isDisabled,
            })}
          >
            <LoadingSpinner size="small" isLoading={isValidating} delayed={100}>
              <Icon glyph={SvgExpandMore} className={styles.icon} />
            </LoadingSpinner>
          </HeadlessCombobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <HeadlessCombobox.Options className={styles.options}>
            {items.length === 0 && query.length > 0 && !isValidating ? (
              <div className={styles.option}>
                Der Suchbegriff konnte leider nicht gefunden werden.
              </div>
            ) : items.length === 0 && query.length === 0 ? (
              <div className={styles.option}>
                Geben Sie einen Suchbegriff ein.
              </div>
            ) : (
              items.map((item, i) => (
                <HeadlessCombobox.Option
                  key={i}
                  value={item}
                  className={({ active }) =>
                    classNames(styles.option, {
                      [styles.active]: active,
                    })
                  }
                >
                  {displayValue(item)}
                </HeadlessCombobox.Option>
              ))
            )}
          </HeadlessCombobox.Options>
        </Transition>
      </div>
    </HeadlessCombobox>
  );
};

interface AsyncProps<T> extends BaseProps<T> {
  fetcher: (s: Searchable) => SWRResponse<T[]>;
}

const Async = <T extends object>(props: AsyncProps<T>) => {
  const filter = useSearchFilter();
  const { fetcher, ...rest } = props;
  const { data, isValidating } = fetcher({
    query: filter.query,
    signal: filter.signal,
  });

  return (
    <Combobox
      {...rest}
      items={data ?? []}
      isValidating={isValidating}
      onInputChange={filter.setSearchTerm}
    />
  );
};

Combobox.Async = Async;

export default Combobox;
