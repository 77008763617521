import { Listbox as HeadlessListbox } from "@headlessui/react";
import classNames from "classnames";
import SvgDone from "../../icons/Done.svg?react";
import SvgExpandLess from "../../icons/ExpandLess.svg?react";
import SvgExpandMore from "../../icons/ExpandMore.svg?react";
import Icon from "./Icon";
import styles from "./Listbox.module.css";

interface BaseProps<T> {
  label: string;
  isDisabled?: boolean;
  displayValue: (value: T) => string;
  onChange: (value: T) => void;
  items: T[];
  value?: T;
  compare?: (a: T, b: T) => boolean;
}

const Listbox = <T extends string | number | object>({
  label,
  value,
  items,
  onChange,
  isDisabled,
  displayValue,
  compare,
}: BaseProps<T>) => {
  return (
    <HeadlessListbox
      value={value}
      onChange={onChange}
      disabled={isDisabled}
      by={compare}
    >
      {({ open }) => (
        <div className={styles.listbox}>
          <div className={styles.label}>
            <HeadlessListbox.Label>{label}</HeadlessListbox.Label>
            <HeadlessListbox.Button
              as="div"
              className={classNames(styles.button, {
                [styles.disabled]: isDisabled,
              })}
            >
              {value ? displayValue(value) : ""}
              {open ? (
                <Icon glyph={SvgExpandLess} className={styles.icon} />
              ) : (
                <Icon glyph={SvgExpandMore} className={styles.icon} />
              )}
            </HeadlessListbox.Button>
          </div>
          <HeadlessListbox.Options className={styles.options}>
            {items.map((item, key) => (
              <HeadlessListbox.Option
                key={key}
                value={item}
                className={({ active, selected }) =>
                  classNames(styles.option, {
                    [styles.active]: active,
                    [styles.selected]: selected,
                    [styles.notSelected]: !selected,
                  })
                }
              >
                {({ selected }) => (
                  <>
                    {selected && (
                      <Icon glyph={SvgDone} className={styles.icon} />
                    )}
                    {displayValue(item)}
                  </>
                )}
              </HeadlessListbox.Option>
            ))}
          </HeadlessListbox.Options>
        </div>
      )}
    </HeadlessListbox>
  );
};

export default Listbox;
