import EmptyPage from "./EmptyPage";
import errorSvg from "../assets/error.svg";
import { PropsWithChildren } from "react";
import styles from "./ErrorPage.module.css";

type Props = PropsWithChildren<{
  title: string;
}>;

const ErrorPage = ({ title, children }: Props) => (
  <EmptyPage title={title} image={errorSvg}>
    <div className={styles.status}>{children}</div>
  </EmptyPage>
);

export default ErrorPage;
