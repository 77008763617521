import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { WretchResponse } from "wretch";
import { useCompany } from "../context/companyContext";
import NotificationDispatch, {
  showErrorNotification,
  showSuccessNotification,
} from "../context/notificationContext";
import useToggle from "../hooks/useToggle";
import {
  EB,
  copyWorksheetData,
  getUseProductListUrl,
  parseErrorResponse,
  postWorksheetImport,
  postWorksheetLock,
  useCategoryList,
} from "../http/endbestaendeApi";
import SvgCopy from "../icons/Copy.svg?react";
import SvgDownload from "../icons/Download.svg?react";
import SvgLock from "../icons/Lock.svg?react";
import SvgUpload from "../icons/Upload.svg?react";
import SvgWarningAmber from "../icons/WarningAmber.svg?react";
import { Permission } from "../types";
import {
  formatPrice,
  getRemoteDataStatus,
  invalidateCacheByUrl,
} from "../utils";
import styles from "./CategoryList.module.css";
import Footer from "./Footer";
import Button, { ConfirmButton } from "./ui/Button";
import Form from "./ui/Form";
import Icon from "./ui/Icon";
import Input from "./ui/Input";
import ModalDialog from "./ui/ModalDialog";
import Table from "./ui/Table";

interface Props {
  worksheet: EB.WorksheetData;
  worksheets: EB.WorksheetData[];
  mutateWorksheet: (value: EB.WorksheetData) => void;
}

const CategoryList = ({ worksheet, worksheets, mutateWorksheet }: Props) => {
  const { company } = useCompany();
  const canWriteSettings =
    company?.permissions.includes(Permission.SettingsWrite) ?? false;
  const { data, isValidating, error, mutate } = useCategoryList(worksheet.id);
  const status = getRemoteDataStatus({ isValidating, error });
  const dispatch = useContext(NotificationDispatch);
  const [importError, setImportError] = useState<EB.ErrorResponse>();
  const importModal = useToggle();
  const copyModal = useToggle();
  const isWorksheetLocked = worksheet.locked_at !== undefined;
  const lastYearWorksheet =
    worksheets[
      worksheets
        .sort(
          (a, b) =>
            new Date(b.end_date).getTime() - new Date(a.end_date).getTime(),
        )
        .findIndex(({ id }) => id === worksheet.id) + 1
    ];

  const invalidateCache = () => {
    mutate();
    invalidateCacheByUrl(getUseProductListUrl(worksheet.id));
  };

  return (
    <>
      <span>
        Enddatum: {new Date(worksheet.end_date).toLocaleDateString("de-DE")}
      </span>
      <Table
        head={
          <tr>
            <th className={styles.left}>Kategorie</th>
            <th className={styles.right}>Gesamt</th>
          </tr>
        }
        body={data?.categories.map((item) => (
          <Row key={item.id} category={item} />
        ))}
        total={data?.categories.length ?? 0}
        remoteDataStatus={status}
      />
      {status === "success" && (
        <Footer
          total={data?.categories.reduce((prev, curr) => prev + curr.amount, 0)}
        >
          <div className={styles.buttons}>
            {!isWorksheetLocked && (
              <ConfirmButton
                onSubmit={() => {
                  if (!company) return;
                  postWorksheetLock(company.id, worksheet.id, {
                    status: "locked",
                  })
                    .then(() => {
                      mutateWorksheet({
                        ...worksheet,
                        locked_at: new Date().toLocaleString(),
                      });
                      dispatch(
                        showSuccessNotification(
                          "Lagerbestand erfolgreich abgeschlossen.",
                        ),
                      );
                    })
                    .catch((err) => dispatch(showErrorNotification(err)));
                }}
                glyph={SvgLock}
                modal={{
                  title: "Lagerbestand abschließen",
                  description: (
                    <p>
                      Wollen Sie den Lagerbestand wirklich abschließen?
                      Anschließend sind keine weiteren Änderungen mehr möglich.
                    </p>
                  ),
                  submitText: "Abschließen",
                }}
              >
                Abschließen
              </ConfirmButton>
            )}
            <a
              href={`/api/v1/companies/${company?.id}/worksheets/${worksheet.id}/export`}
              className={styles.link}
            >
              <Icon glyph={SvgDownload} className={styles.downloadIcon} />
              Export
            </a>
            {canWriteSettings && (
              <Button
                glyph={SvgUpload}
                buttonProps={{
                  disabled: isWorksheetLocked,
                  onClick: importModal.open,
                }}
              >
                Import
              </Button>
            )}
            {lastYearWorksheet && (
              <Button
                glyph={SvgCopy}
                buttonProps={{
                  disabled: !lastYearWorksheet?.locked_at,
                  onClick: copyModal.open,
                  title: !lastYearWorksheet?.locked_at
                    ? "Das Vorjahr muss zuerst abgeschlossen werden, bevor dessen Produkte übernommen werden können."
                    : undefined,
                }}
              >
                Produkte vom Vorjahr übernehmen
              </Button>
            )}
          </div>
        </Footer>
      )}

      <ImportModal
        isOpen={importModal.isOpen}
        onClose={() => {
          importModal.close();
          setImportError(undefined);
        }}
        error={importError}
        onSubmit={(file) => {
          setImportError(undefined);
          postWorksheetImport(worksheet.id, file)
            .then(() => {
              invalidateCache();
              importModal.close();
              dispatch(
                showSuccessNotification("Lagerbestand erfolgreich importiert."),
              );
            })
            .catch((err) => setImportError(parseErrorResponse(err)));
        }}
      />

      <CopyWorksheetModal
        isOpen={copyModal.isOpen}
        onClose={copyModal.close}
        onConfirm={() => copyWorksheetData(worksheet.id)}
        onSuccess={() => {
          invalidateCache();
          dispatch(showSuccessNotification("Produkte erfolgreich übernommen."));
          copyModal.close();
        }}
      />
    </>
  );
};

interface RowProps {
  category: EB.CategoryData;
}

const Row = ({ category: { id, name, amount } }: RowProps) => (
  <tr key={id}>
    <td>
      <Link to={`./${id}`}>{name}</Link>
    </td>
    <td className={styles.right}>{formatPrice(amount ?? 0)}</td>
  </tr>
);

interface FormData {
  file: File;
}

interface ImportModalProps {
  isOpen: boolean;
  onClose: () => void;
  error?: EB.ErrorResponse;
  onSubmit: (file: File) => void;
}

const ImportModal = ({
  isOpen,
  onClose,
  error,
  onSubmit,
}: ImportModalProps) => (
  <ModalDialog
    title="Lagerbestand importieren"
    isOpen={isOpen}
    onClose={onClose}
  >
    <div className={styles.warning}>
      <Icon glyph={SvgWarningAmber} className={styles.warningIcon} />
      Beim Import werden vorherige Daten unwiderruflich überschrieben.
    </div>
    <Form<FormData>
      submitText="Import starten"
      error={error}
      onCancel={onClose}
      onSubmit={(formData) => onSubmit(formData.file)}
    >
      {({ register }) => (
        <Input
          className={styles.fileInput}
          type="file"
          accept=".xlsm"
          required={true}
          {...register("file")}
        />
      )}
    </Form>
  </ModalDialog>
);

interface CopyModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<WretchResponse>;
  onSuccess: () => void;
}

const CopyWorksheetModal = ({
  isOpen,
  onClose,
  onConfirm,
  onSuccess,
}: CopyModalProps) => {
  const dispatch = useContext(NotificationDispatch);
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <ModalDialog
      title="Produkte vom Vorjahr übernehmen"
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        {
          title: "Abbrechen",
          type: "secondary",
          onClick: onClose,
        },
        {
          title: "Produkte übernehmen",
          type: "primary",
          isLoading: isSubmitting,
          onClick: () => {
            setIsSubmitting(true);
            onConfirm()
              .then(onSuccess)
              .catch((err) =>
                dispatch(
                  showErrorNotification(err, `Fehler bei der Übertragung.`),
                ),
              )
              .finally(() => setIsSubmitting(false));
          },
        },
      ]}
    >
      <p>
        Hier können Sie alle Produkte vom Vorjahr in dieses Jahr übernehmen.
      </p>
      <div className={styles.warning}>
        <Icon glyph={SvgWarningAmber} className={styles.warningIcon} />
        Bei der Übernahme werden alle bisher eingetragenen Produkte
        unwiderruflich überschrieben.
      </div>
    </ModalDialog>
  );
};

export default CategoryList;
