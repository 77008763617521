import { PropsWithChildren } from "react";
import styles from "./Footer.module.css";
import { formatPrice } from "../utils";

type Props = PropsWithChildren<{
  total: number | undefined;
}>;

const Footer = ({ total, children }: Props) => (
  <div className={styles.footer}>
    <div>{children}</div>
    {total !== undefined && (
      <p className={styles.total}>
        Summe:{" "}
        <strong className={styles.totalPrice}>{formatPrice(total)}</strong>
      </p>
    )}
  </div>
);

export default Footer;
