import { useWorksheet } from "../context/worksheetContext";

import ErrorText from "./ui/ErrorText";
import Detail from "./Detail";
import ErrorPage from "./ErrorPage";

const DetailPage = () => {
  const { worksheet, isValidating, errorCode } = useWorksheet();
  const isSuccess = worksheet && !isValidating && !errorCode;

  return (
    <>
      {isSuccess && <Detail worksheet={worksheet} />}

      {errorCode && (
        <ErrorPage title="Fehler beim Laden der Daten.">
          {errorCode === 404 && (
            <ErrorText text="Dieses Jahr konnte nicht gefunden werden." />
          )}
        </ErrorPage>
      )}
    </>
  );
};

export default DetailPage;
