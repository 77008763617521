import { ReactNode } from "react";
import useToggle, { UseToggleProps } from "../../hooks/useToggle";
import Modal from "./Modal";

interface Props {
  title: string;
  description?: ReactNode;
  submitText?: string;
  children: (toggle: UseToggleProps) => ReactNode;
  onConfirm: () => void;
  onClose?: () => void;
}

const Confirm = ({
  title,
  description,
  submitText = "Löschen",
  onConfirm,
  onClose,
  children,
}: Props) => {
  const toggle = useToggle();

  return (
    <>
      <Modal
        title={title}
        isOpen={toggle.isOpen}
        onSubmit={() => (toggle.close(), onConfirm())}
        onClose={() => (toggle.close(), onClose?.())}
        submitText={submitText}
      >
        {description}
      </Modal>
      {children(toggle)}
    </>
  );
};

export default Confirm;
