import { ReactNode } from "react";
import { KeyedMutator } from "swr";
import { EB, useWorksheetList } from "../http/endbestaendeApi";
import { getRemoteDataStatus } from "../utils";
import ErrorPage from "./ErrorPage";
import styles from "./Worksheet.module.css";
import LoadingSpinner from "./ui/LoadingSpinner";
import Page from "./ui/Page";

interface Props {
  companyId: EB.CompanyId;
  pageTitle?: string;
  children:
    | ReactNode
    | ((props: {
        worksheets: EB.WorksheetData[];
        mutate: KeyedMutator<{
          worksheets: EB.WorksheetData[];
        }>;
      }) => ReactNode);
}

const Worksheet = ({ companyId, pageTitle, children }: Props) => {
  const { data, isValidating, error, mutate } = useWorksheetList(companyId);
  const status = getRemoteDataStatus({ isValidating, error });

  return (
    <>
      {status === "success" && data && (
        <Page title={pageTitle ?? ""}>
          {typeof children === "function"
            ? children({
                worksheets: data.worksheets,
                mutate,
              })
            : children}
        </Page>
      )}

      {status === "validating" && (
        <div className={styles.loadingSpinner}>
          <LoadingSpinner />
        </div>
      )}

      {status === "failure" && (
        <ErrorPage title="Fehler beim Laden der Daten." />
      )}
    </>
  );
};

export default Worksheet;
