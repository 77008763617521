import { PropsWithChildren, useContext, useState } from "react";
import { WretchResponse } from "wretch";
import NotificationDispatch, {
  showErrorNotification,
} from "../context/notificationContext";
import ModalDialog from "./ui/ModalDialog";

type Props = PropsWithChildren<{
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<WretchResponse>;
  onSuccess: () => void;
}>;

const DeleteProductModal = ({
  title,
  isOpen,
  onClose,
  onConfirm,
  onSuccess,
  children,
}: Props) => {
  const dispatch = useContext(NotificationDispatch);
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <ModalDialog
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        {
          title: "Abbrechen",
          type: "secondary",
          onClick: onClose,
        },
        {
          title: "Löschen",
          type: "destructive",
          isLoading: isSubmitting,
          onClick: () => {
            setIsSubmitting(true);
            onConfirm()
              .then(onSuccess)
              .catch((err) =>
                dispatch(showErrorNotification(err, `Fehler beim Löschen.`)),
              )
              .finally(() => setIsSubmitting(false));
          },
        },
      ]}
    >
      {children}
    </ModalDialog>
  );
};

export default DeleteProductModal;
