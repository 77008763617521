import SvgError from "../../icons/Error.svg?react";
import styles from "./ErrorText.module.css";
import Icon from "./Icon";

interface Props {
  text: string;
}

const ErrorText = ({ text }: Props) => (
  <span className={styles.text}>
    <Icon glyph={SvgError} className={styles.icon} /> {text}
  </span>
);

export default ErrorText;
