import { Link } from "react-router-dom";
import endbestaendeSVG from "../assets/endbestaende.svg";
import AccountButton from "./AccountButton";
import styles from "./Header.module.css";
import { PropsWithChildren } from "react";

const Header = ({ children }: PropsWithChildren) => {
  return (
    <header className={styles.header}>
      <div className={styles.left}>
        <Link to={"/"} className={styles.logo}>
          <img src={endbestaendeSVG} alt="HGV Endbestände" />
        </Link>
        {children}
      </div>
      <div className={styles.right}>
        <AccountButton />
      </div>
    </header>
  );
};

export default Header;
