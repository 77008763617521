import classNames from "classnames";
import { Fragment, ReactNode } from "react";
import useToggle from "../../hooks/useToggle";
import SvgExpandLess from "../../icons/ExpandLess.svg?react";
import SvgExpandMore from "../../icons/ExpandMore.svg?react";
import styles from "./ExpandableRow.module.css";
import Icon from "./Icon";

interface Props {
  cellType: "td" | "th";
  cells: ReactNode[];
  expandedContent?: ReactNode;
}

const ExpandableRow = ({
  cellType: CellType,
  cells,
  expandedContent,
}: Props) => {
  const expandedRow = useToggle();
  const isExpandable = expandedContent !== undefined;

  return (
    <>
      <tr
        className={classNames(styles.row, styles.level1, {
          [styles.clickable]: isExpandable,
        })}
        onClick={() => isExpandable && expandedRow.toggle()}
      >
        <CellType
          className={classNames({ [styles.expanded]: expandedRow.isOpen })}
        ></CellType>
        <CellType>
          {isExpandable && (
            <Icon
              glyph={expandedRow.isOpen ? SvgExpandLess : SvgExpandMore}
              className={styles.icon}
            />
          )}
        </CellType>
        {cells.map((cell, index) => (
          <Fragment key={index}>{cell}</Fragment>
        ))}
      </tr>
      {expandedRow.isOpen && (
        <tr className={classNames(styles.row, styles.level2)}>
          <CellType
            className={classNames({ [styles.expanded]: expandedRow.isOpen })}
          ></CellType>
          <CellType colSpan={cells.length + 1}>{expandedContent}</CellType>
        </tr>
      )}
    </>
  );
};

export default ExpandableRow;
