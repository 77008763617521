import classNames from "classnames";
import { ButtonHTMLAttributes, PropsWithChildren, ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";
import { Glyph } from "../../types";
import styles from "./Button.module.css";
import Confirm from "./Confirm";
import Icon from "./Icon";
import LoadingSpinner from "./LoadingSpinner";

export type ButtonType = "primary" | "secondary" | "destructive" | "control";

type Props = PropsWithChildren<{
  type?: ButtonType;
  size?: "small" | "default";
  glyph?: Glyph;
  isLoading?: boolean;
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
}>;

const createClassNames = ({
  type = "primary",
  size = "default",
  buttonProps,
}: Props) =>
  classNames(styles.button, buttonProps?.className, {
    [styles.primary]: type === "primary",
    [styles.secondary]: type === "secondary",
    [styles.destructive]: type === "destructive",
    [styles.control]: type === "control",
    [styles.default]: size === "default",
    [styles.small]: size === "small",
    [styles.disabled]: buttonProps?.disabled,
  });

const Button = (props: Props) => {
  const { children, buttonProps, glyph, isLoading } = props;
  return (
    <button type="button" {...buttonProps} className={createClassNames(props)}>
      {isLoading && (
        <LoadingSpinner className={styles.loadingSpinner} size="small" />
      )}
      {glyph && <Icon className={styles.icon} glyph={glyph} />}
      {children}
    </button>
  );
};

interface LinkButtonProps extends Props {
  linkProps: LinkProps;
}

export const LinkButton = (props: LinkButtonProps) => {
  const { children, glyph, linkProps } = props;
  return (
    <Link {...linkProps} className={createClassNames(props)}>
      {glyph && <Icon className={styles.icon} glyph={glyph} />}
      {children}
    </Link>
  );
};

interface ConfirmButtonProps {
  onSubmit: () => void;
  modal: {
    title: string;
    description: ReactNode;
    submitText: string;
  };
  className?: string;
  children?: string;
  glyph?: Glyph;
  type?: ButtonType;
  disabled?: boolean;
}

export const ConfirmButton = ({
  modal,
  onSubmit,
  glyph,
  disabled,
  type,
  children,
  className,
}: ConfirmButtonProps) => (
  <Confirm {...modal} onConfirm={onSubmit}>
    {({ open }) => (
      <Button
        type={type}
        glyph={glyph}
        buttonProps={{
          title: modal.title,
          onClick: open,
          disabled: disabled,
          className,
        }}
      >
        {children}
      </Button>
    )}
  </Confirm>
);

export default Button;
