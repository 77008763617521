import { ReactNode } from "react";
import {
  DefaultValues,
  FieldValues,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import { EB } from "../../http/endbestaendeApi";
import Button from "./Button";
import ErrorText from "./ErrorText";
import styles from "./Form.module.css";

export type FormChildrenProps<TFieldValues extends FieldValues> =
  UseFormReturn<TFieldValues> & {
    disabled?: boolean;
  };

type FormProps<TFieldValues extends FieldValues> = {
  defaultValues?: DefaultValues<TFieldValues>;
  disabled?: boolean;
  error?: EB.ErrorResponse;
  submitText?: ReactNode;
  isSubmitting?: boolean;
  onCancel?: () => void;
  onSubmit?: (formData: TFieldValues, reset: () => void) => void;
  children?:
    | ReactNode
    | ((useFormProps: FormChildrenProps<TFieldValues>) => ReactNode);
};

const Form = <TFieldValues extends FieldValues>({
  defaultValues,
  disabled,
  error,
  submitText = "Speichern",
  isSubmitting,
  onCancel,
  onSubmit,
  children,
}: FormProps<TFieldValues>) => {
  const useFormProps = useForm<TFieldValues>({
    defaultValues,
    mode: "onBlur",
  });

  return (
    <form
      onSubmit={(e) => {
        if (onSubmit) {
          e.stopPropagation();
          useFormProps.handleSubmit((onValid) =>
            onSubmit(onValid, useFormProps.reset),
          )(e);
        }
      }}
    >
      {typeof children === "function"
        ? children({ ...useFormProps, disabled })
        : children}

      {error && (
        <div className={styles.error}>
          <ErrorText text={getErrorMessage(error)} />
        </div>
      )}

      {onSubmit && (
        <div className={styles.submitBar}>
          {onCancel && (
            <Button type="secondary" buttonProps={{ onClick: onCancel }}>
              Abbrechen
            </Button>
          )}
          <Button
            type="primary"
            isLoading={isSubmitting}
            buttonProps={{
              type: "submit",
              disabled: disabled || isSubmitting,
            }}
          >
            {submitText}
          </Button>
        </div>
      )}
    </form>
  );
};

const getErrorMessage = ({ error }: EB.ErrorResponse) =>
  error.details
    ? `Fehler in Zeile ${error.details.row} im Blatt '${error.details.worksheet}'.`
    : "Es ist ein unbekannter Fehler aufgetreten.";

export default Form;
